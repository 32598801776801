// base url for all api's
//   https://smonitor.websiteuptimerobot.com
export const BASE_URL = "https://smonitor.websiteuptimerobot.com";
// access token for header.
export const accesstoken = localStorage.getItem("access_token");
export const config = {
  headers: {
    Authorization: `Bearer ${accesstoken}`,
  },
};
